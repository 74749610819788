import React, { memo, useEffect, useState, useRef } from 'react';
import { Scene, Zoom, Fullscreen } from '@antv/l7';
import { Mapbox } from '@antv/l7-maps';
import { createUseStyles } from 'react-jss';
import tileStyle from './MapTileStyle';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { mapCountryCoordinates } from 'src/constants';
import {
  createMapData,
  extractData,
  fetchReportData,
  getGlobalFilters,
  mapMarkerConfig,
  setupGeofenceLayers,
  setupPopups,
  setupScene,
} from './utils';

const useStyles = createUseStyles({
  map: {
    height: '100%',
    width: '100%',
    position: 'relative',
    '& .l7-control-container .l7-column.l7-left': {
      display: 'none',
    },
  },
});

const Map = (props) => {
  const { reportId, chartData: mainReportData, visualizeMetaData } = props;
  const classes = useStyles();
  const id = reportId || 'map-container';
  const location = useLocation();
  const isVisualizeDashboard = location.pathname.includes('visualize');

  // refs required to cleanup the map scene
  const pointLayerRef: any = useRef(null);
  const geofenceLayerRef: any = useRef(null);
  const sceneRef: any = useRef(null);

  const orgConfigData = useSelector((state: any) => state.masterData.organisationConfig);
  const chartsMetaData = useSelector((state: any) => state.chartsMetaData);
  const dashboardGroupMetaData = useSelector(
    (state: any) => state.dashboardGroupMetaData,
  );
  const dashboards = dashboardGroupMetaData?.metadata?.dashboards || [];
  const dashboardFilter = useSelector((state: any) => state.dashboardFilter);
  const supportedFilters = useSelector(
    (state: any) => state.masterData?.supportedFilters,
  );
  const isAutoRefresh = dashboardGroupMetaData?.isAutoRefresh;
  const lastUpdatedTime = dashboardGroupMetaData?.lastUpdatedTime;
  const globalFilters = getGlobalFilters(
    dashboardGroupMetaData,
    dashboardFilter,
    supportedFilters,
  );
  const enableDummyDataSwitch = useSelector(
    (state: any) => state.reportMetaData.dummyDataSwitch,
  );

  const [linkedReports, setLinkedReports] = useState<string[]>([]);
  const [linkedReportsData, setLinkedReportsData] = useState<any>({});
  const [mainReportGeofence, setMainReportGeofence] = useState<number>(0);

  useEffect(() => {
    if (isVisualizeDashboard) {
      setLinkedReports(
        chartsMetaData?.chartOptions?.geolocation?.overlayReports?.data || [],
      );
      setMainReportGeofence(
        chartsMetaData?.chartOptions?.geolocation?.geofenceRadius?.data || 0,
      );
    } else {
      const reports =
        dashboards.flatMap(
          (dashboard) => dashboard?.dashboardVisualisations?.reports,
        ) || [];
      const overlayReportsArray: string[] = [];

      reports.forEach((report) => {
        if (report.reportId === id) {
          const overlayReportsOption = report?.chartOptions?.find(
            (option) => option.option === 'overlayReports',
          );
          if (overlayReportsOption) {
            overlayReportsArray.push(
              ...JSON.parse(overlayReportsOption.data).value,
            );
          }

          if (!mainReportGeofence) {
            const geofenceRadiusOption = report?.chartOptions?.find(
              (option) => option.option === 'geofenceRadius',
            );
            if (geofenceRadiusOption) {
              setMainReportGeofence(
                JSON.parse(geofenceRadiusOption.data).value || 0,
              );
            }
          }
        }
      });

      setLinkedReports(overlayReportsArray || []);
    }
  }, []);

  const fetchAllReportData = async () => {
    const newLinkedReportsData: any = {};

    for (const reportId of linkedReports) {
      const reportData = await fetchReportData(
        reportId,
        globalFilters,
        enableDummyDataSwitch,
      );
      if (reportData) {
        newLinkedReportsData[reportId] = reportData;
      }
    }

    setLinkedReportsData(newLinkedReportsData);
  };

  useEffect(() => {
    if (linkedReports.length) {
      fetchAllReportData();
    }
  }, [linkedReports]);

  useEffect(() => {
    // Fetch linked reports data on auto refresh
    if (isAutoRefresh && linkedReports.length) {
      fetchAllReportData();
    }
  }, [lastUpdatedTime, isAutoRefresh]);

  const getMapCenter = () => {
    const country = orgConfigData?.defaultCountry;
    return mapCountryCoordinates[country] || mapCountryCoordinates.default;
  };

  const initialiseMap = () => {
    const mapData = createMapData(
      mainReportData,
      linkedReportsData,
      mainReportGeofence,
    );
    const scene = new Scene({
      id,
      map: new Mapbox({
        style: tileStyle,
        center: getMapCenter(),
        zoom: 8,
      }),
    });

    scene.on('loaded', () => {
      scene.addControl(new Zoom());
      scene.addControl(new Fullscreen());

      // Load custom images for markers
      Object.keys(mapMarkerConfig).forEach((key) => {
        scene.addImage(key, mapMarkerConfig[key].image);
      });

      // Initialize the map scene with the data
      setupScene(scene, mapData, pointLayerRef);
      setupGeofenceLayers(scene, mapData, geofenceLayerRef);
      const allData = extractData(visualizeMetaData, linkedReportsData);
      setupPopups(scene, pointLayerRef.current, allData);
    });

    sceneRef.current = scene;
  };

  useEffect(() => {
    initialiseMap();
    const scene = sceneRef.current;
    return () => {
      scene.destroy();
      pointLayerRef.current = null;
      geofenceLayerRef.current = null;
      sceneRef.current = null;
    };
  }, [id, mainReportData]);

  // useEffect(() => {
  //   const scene = sceneRef.current;
  //   if (isVisualizeDashboard) {
  //     return () => {
  //       scene?.destroy();
  //       pointLayerRef.current = null;
  //       geofenceLayerRef.current = null;
  //       sceneRef.current = null;
  //     };
  //   }
  //   return;
  // }, [mainReportData]);

  useEffect(() => {
    const scene = sceneRef.current;
    if (!scene) {
      initialiseMap();
    } else {
      const mapData = createMapData(
        mainReportData,
        linkedReportsData,
        mainReportGeofence,
      );

      // Setup the map scene with the new data
      setupScene(scene, mapData, pointLayerRef);
      setupGeofenceLayers(scene, mapData, geofenceLayerRef);
      const allData = extractData(visualizeMetaData, linkedReportsData);
      setupPopups(scene, pointLayerRef.current, allData);
      return;
    }
  }, [mainReportData, linkedReportsData, mainReportGeofence]);

  return <div id={id} className={classes.map}></div>;
};

export default memo(Map);
