/* eslint-disable no-lonely-if */
import { Button, Input, Spin, message, Menu, Modal, Tabs, Switch, Popover, Dropdown, Row } from 'antd';
import React, { useEffect, memo, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { useLazyQuery } from '@apollo/client';
import 'react-resizable/css/styles.css';
import { useDashboard } from './hooks/dashboardHook';
import { useParams } from 'react-router-dom';
import TitleBar from './dashboardParts/titleBar';
import ChartContainer from './dashboardParts/chartContainer';
import useLayout from './hooks/useLayout';
import { useDownloadReport } from './hooks/downloadReport';
import { useRemoveReportFromDashboard } from './hooks/removeDashboard';
import { SizeMe } from 'react-sizeme';
import config from 'src/config';
import { Prompt } from 'react-router-dom';
import MobileNavBarFilters from '../../common/MobileNavBarDashboardFilters';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import {
  EditOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  CloseOutlined,
  PlusOutlined,
  EllipsisOutlined,
  MobileOutlined,
  DesktopOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  INITIALIZE_DASHBOARD_FILTERS,
  NAME_CHANGED,
  RESET_DASHBOARD_GROUP_NAME_AND_ID,
  SET_VISIBLE_CHARTS,
} from 'src/reduxActions/actionNameEnums';
import { CREATE_DASHBOARD_FAST_CREATE, MOVE_DASHBOARD_TO_DASHBOARDGROUP,
  REMOVE_DASHBOARD_FROM_DASHBOARDGROUP, UPDATE_DASHBOARD} from './hooks/dashboardQueries';
import { useAddReportDashboardPage } from './hooks/addReport';
import AddReportModal from './dashboardParts/addReport';
import { createUseStyles } from 'react-jss';
import ChildReportModal from 'src/components/common/ChildReportModal';
import VisibilitySensor from 'react-visibility-sensor';
import { DownloadOutlined } from '@ant-design/icons';
import {
  CHANGE_DOWNLOAD_MODAL_STATE,
  DUMMY_DATA_SWITCH,
  SET_FULL_SCREEN,
  SET_LAST_UPDATED_TIME,
  SET_AUTO_REFRESH,
  SET_REFRESH_REPORTS_DATA,
  CHANGE_DASHBOARD_TAB,
  SET_DASHBOARD_METADATA,
} from 'src/reduxActions/actionNameEnums';
import { getProjectName } from 'src/components/common/utils/utils';
import { ReactComponent as NotFoundImage } from 'src/assets/Unauthorized.svg';
import { ReactComponent as DownloadIcon } from 'src/assets/Vector.svg';
import NavigationPane from './dashboardParts/NavigationPane';
import StatusDisplay from 'src/components/pagesv2/StatusDisplay';
import lodashMap from 'lodash/map';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { projectNames, entityTypes, dashboardGroupTypes, LAYOUT_VIEW_TYPES } from 'src/constants';
import { ReactComponent as FullScreenIcon } from 'src/assets/FullScreen.svg';
import { ReactComponent as FullScreenExitIcon } from 'src/assets/FullScreenExit.svg';
import { ReactComponent as AutoRefresh } from 'src/assets/AutoRefresh.svg';
import DashboardOptions from './dashboardParts/dashboardOptions';
import { useSchedulerPage } from 'src/components/pages/alertsAndScheduler/hooks/scheduler';
import SchedulerDrawer from '../reports/alertsAndScheduler/SchedulerDrawer';
import { GET_DASHBOARD_REPORTS_RELOADING_DATA } from 'src/api/routes';
import useLazyAPI from 'src/components/common/hooks/useLazyAPI';
import { getFormattedTime } from 'src/components/common/utils/utils';
const { confirm } = Modal;

const useStyle = createUseStyles({
  dashboardTabs: {
    height: '100%',
    margin: '0.5rem 0',
    overflow: 'auto',
    scrollbarWidth: 'none',
    '& .ant-tabs-nav': {
      margin: '0px !important',
      padding: '0 0.5rem',
    },
    '& .ant-tabs-nav-list': {
      gap: '0.25rem',
    },
    '& .ant-tabs-tab': {
      border: '0px',
      padding: '0px 10px 0px !important',
      borderRadius: '5px 5px 0 0 !important',
      margin: '0 !important',
      fontSize: '0.9rem !important',
      overflow: 'auto',
    },
    '& .ant-tabs-tab-active': {
      borderBottom: '2px solid #1890ff !important',
    },
  },
  moreOutlined: {
    fontSize: '20px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  editButtons: {
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    padding: '0.8rem 0.5rem',
    fontSize: '0.7rem',
  },
  dashboardContainer: {
    minHeight: '80vh',
    maxWidth: '100%',
    height: 'auto',
    '& .react-grid-item': {
      transform: '0px 2px !important',
    },
    margin: '0 0.2rem',
    position: 'relative',
    zIndex: 0,
  },
  downloadIcon: {
    borderRadius: '4px',
    fontSize: '12px',
    margin: '0 5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addReportButton: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  editDashboardButton:{
    marginRight: '0.5rem',
  },
  dummyDataToggle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap:'5px',
    margin: '0 0.5em',
  },
  tabBarExtraContent: {
    display: 'flex',
    alignItems: 'center',
  },
  dashboardListPopover: {
    width: '20rem',
    '& .ant-popover-inner-content': {
      padding: '0px',
    },
  },
  moreMenu: {
    display: 'flex',
    gap: '.5em',
    alignItems: 'center',
    marginRight: '10px',
  },
  moreMenuIcon: {
    padding: '5px',
    fontSize: '17px',
  },
  draggableTabsParent: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px 7px',
    gap: '1em',
    position: 'sticky',
    top: '0px',
    zIndex: '1',
  },
  draggableTabs: {
    display: 'flex',
    gap: '.2em',
    marginTop: '5px',
    overflowX: 'scroll',
    alignItems: 'center',
  },
  draggableTab: {
    backgroundColor: '#fafafa',
    border: '1px solid #f0f0f0',
    padding: '0.75em',
    cursor: 'pointer',
    borderRadius: '10px 10px 0px 0px',
  },
  tabInputBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5em',
  },
  tabRenamingBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5em',
    whiteSpace: 'nowrap',
  },
  addDashboardIcon: {
    alignSelf: 'center',
    backgroundColor: 'rgb(250, 250, 250)',
    border: '1px solid rgb(240, 240, 240)',
    padding: '0.75em 1em',
  },
  morePlusButton: {
    display: 'flex',
    gap: '1em',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '5px',
  },
  tabIcons:{
    display: 'flex',
    gap: '0.5rem',
  },
  loadingDashboardPageSpinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
});

const Dashboard = (props) => {
  const {
    dashboardId,
    dashboard,
    setInEditMode,
    inEditMode,
    dashboardTab,
    isSticky,
    setIsSticky,
    removeFromLocalStorage,
    getCachedDashboards,
    isTabletOrMobile,
    getReportsReloadVisualizationData,
    selectedLayoutView,
    onCompleteInput,
  } = props;
  const {
    fetchInProgress,
    onApplyFilter,
    onResetFilter,
    reportName,
    listenForRefresh,
    onRefetchDashboardData,
    submitableFilter,
    refetchMetaData,
    type,
    canEditDefaultDashboard,
    dashboardGroupId,
    setFilterRemoveFromLocal,
    dashboards,
  } = dashboard;
  const dashboardItem = dashboards[dashboardTab] || {};
  const { dashboardVisualisations } = dashboardItem;
  const dispatch = useDispatch();
  const {
    layout,
    chartData,
    onChangeAllowLayoutChanges,
    canChangeLayout,
    onChangeLayout,
    setCanChangeLayout,
    editingPosition,
  } = useLayout(
    dashboardVisualisations?.reports || [],
    dashboardVisualisations?.dashboard._id,
    selectedLayoutView,
  );
  const { deleteInProgress, onDeleteReportFromDashboard } = useRemoveReportFromDashboard({
    dashboardId,
    dashboardTab,
    refetchMetaData,
    onRefetchDashboardData});
  const { onDownload, onDownloadUnderlyingData } = useDownloadReport();
  const firstUpdate = useRef(true);
  const classes = useStyle();
  const reportsAll = useSelector((state:any) => state.masterData.reports);
  const dashboardGroupMetaData = useSelector((state: any) => state.dashboardGroupMetaData);
  const {
    isFullScreen,
    lastUpdatedTime,
    isAutoRefresh,
    visibleCharts,
    autoReloadFrequency,
    isFilterApplied,
    reportList,
    dashboardGridColumns = 12,
  } = dashboardGroupMetaData;
  const filtersObjects = useSelector((state: any) => state.dashboardFilter);
  const updatedName = useSelector((state: any) => state?.editDashboard.nameChange);
  const filters = lodashMap(filtersObjects, (value) => lodashMap(value, (innerValue) => innerValue));

  const updateDashboardTabName = async () => {
    await onChangeAllowLayoutChanges(inEditMode);
    if(!inEditMode && updatedName) {
      onCompleteInput(
        null,
        dashboardItem.dashboardVisualisations
          .dashboard._id,
      );
    }
  };

  useEffect(() => {
    setCanChangeLayout(inEditMode);
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    updateDashboardTabName();
  }, [inEditMode]);

  const setVisibleCharts = (payload) => {
    dispatch({ type: SET_VISIBLE_CHARTS, payload });
  };

  const onVisibilityChange = (val, chartId) => {
    if(val) {
      setVisibleCharts(chartId);
    }
  };
  const reportIds = chartData.map((reports: any) => reports.reportId);

  let intervalId;
  let nextCallTime = 0; // Variable to track the time for the next API call
  const delay = autoReloadFrequency;
  const [timeoutIds, setTimeoutIds] = useState<any>([]);
  // saving timeoutIds and the filters corresponding to it in order to clear all the timeouts when the filters are changed

  const clearAllTimeouts = () => {
    // handling the case if filter are applied when refresh is made but the response is not received yet
    if(timeoutIds[0]?.submitableFilter?.length !== submitableFilter?.length){
      timeoutIds.forEach((id) => {
        if(id.submitableFilter.length !== submitableFilter.length) {
          clearTimeout(id.intervalId);
        }
      });
      const filteredTimeoutIds = timeoutIds.filter(id => id.submitableFilter.length === submitableFilter.length);
      setTimeoutIds(filteredTimeoutIds);
      return;
    }
    timeoutIds.forEach((id) => clearTimeout(id.intervalId));
    dispatch({ type: SET_REFRESH_REPORTS_DATA, payload: {}});
    setTimeoutIds([]);
  };

  useEffect(() => {
    if(timeoutIds.length > 0 && (!isAutoRefresh || timeoutIds[0]?.submitableFilter?.length !== submitableFilter?.length)){
      clearAllTimeouts();
    }
  },[timeoutIds]);

  const MakeAPICallAndScheduleNext = async () => {
    try {
      await getReportsReloadVisualizationData({
        variables: {
          dashboardGroupId,
          reportIds,
          globalFilters: submitableFilter,
          autoReloadFrequency,
        },
      });

      const now = Date.now();
      dispatch({ type: SET_LAST_UPDATED_TIME, payload: now });
      nextCallTime = now + delay;

      if (document.visibilityState === 'visible' && isAutoRefresh && window.location.pathname.startsWith('/dashboard')) {
        intervalId = setTimeout(MakeAPICallAndScheduleNext, delay);
        setTimeoutIds([{intervalId, submitableFilter}]);
      }
    } catch (error) {
      console.error('An error occurred during data refresh:', error);
    }
  };
  useEffect(() => {
    if (
      reportIds.length > 0 &&
      lastUpdatedTime !== 0 &&
      reportList.length === 0 &&
      isAutoRefresh && isFilterApplied
    ) {
      clearAllTimeouts();
      const now = Date.now();
      const timeDelay = now - lastUpdatedTime; // Time since the last API call
      if (timeDelay > delay) {
        MakeAPICallAndScheduleNext();
      } else {
        intervalId = setTimeout(MakeAPICallAndScheduleNext, delay - timeDelay);
        setTimeoutIds([...timeoutIds, {intervalId, submitableFilter}]);
      }
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          const now = Date.now();
          const timeUntilNextCall = Math.max(nextCallTime - now, 0);
          intervalId = setTimeout(
            MakeAPICallAndScheduleNext,
            timeUntilNextCall,
          );
        } else {
          clearTimeout(intervalId);
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () => {
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange,
        );
        clearTimeout(intervalId);
      };
    }
    if (!isAutoRefresh) {
      clearAllTimeouts();
    }
    return;
  }, [isAutoRefresh, isFilterApplied, reportList.length, submitableFilter.length]);

  return (
    <>
      {!isTabletOrMobile && filters[dashboardTab]?.length > 0 && (
        <TitleBar
          dashboardId={dashboardVisualisations.dashboard._id}
          filters={filters[dashboardTab]}
          onApplyFilter={onApplyFilter}
          onResetFilter={onResetFilter}
          isSticky={isSticky}
          setIsSticky={setIsSticky}
          dashboardTab={dashboardTab}
          dashboardGroupId={dashboardGroupId}
          removeFromLocalStorage={removeFromLocalStorage}
          getCachedDashboards={getCachedDashboards}
        />
      )}
      <Prompt
        when={canChangeLayout}
        message="Are you sure you want to leave?"
      />
      <div className={classes.dashboardContainer}>
        <SizeMe>
          {({ size }: any) => {
            const rowHeight = dashboardGridColumns === 12 ? 30 : (window.screen.height / dashboardGridColumns);
            const margin = dashboardGridColumns === 12 ? [8, 8] : [0, 0];
            return (
              <GridLayout
                className="layout"
                layout={layout}
                autoSize={true}
                cols={dashboardGridColumns}
                compactType={null}
                rowHeight={rowHeight}
                containerPadding={[4, 2]}
                margin={margin}
                preventCollision={true}
                width={size.width}
                onLayoutChange={onChangeLayout}
              >
                {chartData.map((chart: any, index) => {
                  let padding;
                  if (!isTabletOrMobile && chart?.chartConfig?.chartPadding) {
                    const chartPadding = chart.chartConfig.chartPadding;
                    const paddingLeft = chartPadding?.chartPaddingLeft?.data;
                    const paddingRight = chartPadding?.chartPaddingRight?.data;
                    const paddingTop = chartPadding?.chartPaddingTop?.data;
                    const paddingBottom =
                      chartPadding?.chartPaddingBottom?.data;
                    padding = `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`;
                  }
                  return (
                    <div
                      key={chart.reportId}
                      style={{ borderRadius: '8px', padding }}
                    >
                      <VisibilitySensor
                        partialVisibility
                        onChange={(val) =>
                          onVisibilityChange(val, chart.reportId)
                        }
                      >
                        {(visibleCharts.hasOwnProperty(chart.reportId) &&
                          visibleCharts[chart.reportId]) ||
                        isFullScreen ? (
                          <ChartContainer
                            listenForRefresh={listenForRefresh}
                            onDeleteReportFromDashboard={
                              onDeleteReportFromDashboard
                            }
                            onDownload={onDownload}
                            onDownloadUnderlyingData={onDownloadUnderlyingData}
                            dashboardId={dashboardVisualisations?.dashboard._id}
                            dashboardTab={dashboardTab}
                            filters={submitableFilter}
                            setFilterRemoveFromLocal={setFilterRemoveFromLocal}
                            inEditMode={inEditMode}
                            chartReport={chart}
                            reportsAll={reportsAll}
                            clearAllTimeouts={clearAllTimeouts}
                            {...chart}
                          />
                        ) : (
                          <div style={{ height: '100%', width: '100%' }} />
                        )}
                      </VisibilitySensor>
                    </div>
                  );
                })}
              </GridLayout>
            );
          }}
        </SizeMe>
      </div>
    </>
  );
};

const MoreComponent = ({ orderedDashboardsList, startIndex, endIndex, handleDashboardTabChange, handleDeletion, inEditMode }) => {
  const classes = useStyle();
  const dropdownMenu = (
    <Menu>
      {
        orderedDashboardsList.map((item, idx) => {
          if(idx > startIndex && idx < endIndex) return;
          return (
            <div className={classes.moreMenu} key={item.dashboardVisualisations.dashboard._id}>
              <Menu.Item key={idx.toString()} onClick={() => handleDashboardTabChange((idx).toString(), true)}>
                {item.dashboardVisualisations.dashboard.name}
              </Menu.Item>
              {inEditMode ? <CloseOutlined onClick={() => handleDeletion(idx)}/> : null}
            </div>
          );
        })
      }
    </Menu>
  );
  return (
      <Dropdown
        overlay={dropdownMenu}
      >
        <EllipsisOutlined
          className={classes.moreMenuIcon}
        />
      </Dropdown>
  );
};

const DashboardPage = () => {
    const classes = useStyle();
    const history = useHistory();
    const { dashboardId: dashboardGroupId, tabId } = useParams();
    const tabsRef = useRef<any>(null);
    const dashboard =  useDashboard(dashboardGroupId);
    const [inEditMode, setInEditMode] = useState(false);
    const projectName = getProjectName();
    const {
      dashboards = [],
      fetchInProgress,
      refetchMetaData,
      canEditDefaultDashboard,
      dashboardTab,
      setDashboardTab,
      onRefetchDashboardData,
      dashboardGroupType,
      updateReportPositions,
      suggestedFilter,
      dashboardError,
      numSchedules,
      submitableFilter,
      activeCachedDashboard,
    } = dashboard;
    const dispatch = useDispatch();
    const location = useLocation();
    const updatedName = useSelector((state: any) => state?.editDashboard.nameChange);
    const isTabletOrMobile = useSelector((state: any) => state.config.isTabletOrMobile);
    const analyticsTopBar = useSelector((state: any) => state.config.analyticsTopBar);
    const [indexChange,setIndexChange] = useState(-1);
    const [isSticky, setIsSticky] = useState(true);
    const enableDummyDataSwitch = useSelector((state:any) => state.reportMetaData.dummyDataSwitch);
    const isDefaultEditable = useSelector((state:any) => state.masterData?.organisationConfig?.isDefaultEditable || false);
    const dashboardGroupMetaData = useSelector((state: any) => state.dashboardGroupMetaData);
    const {
      dashboardGroupName,
      isFullScreen,
      dashboardType,
      dashboardDescription,
      dashboardCategory,
      isAutoRefresh,
      lastUpdatedTime,
      metadata,
      autoReloadFrequency,
    } = dashboardGroupMetaData;
    const [loading, setLoading] = useState(false);
    const [popoverCaretVisible, setPopoverCaretVisible] = useState(false);
    const [dashboardSearchText, setDashboardSearchText] = useState('');
    const [selectedLayoutView, setSelectedLayoutView] = useState(LAYOUT_VIEW_TYPES.DESKTOP);
    const [dashboardOptions, setDashboardOptions] = useState(false);
    const organisationConfig = useSelector((state: any) => state.masterData.organisationConfig);
    const isInternalSupportEmployee = useSelector((state:any) => state?.masterData?.userConfig?.isInternalSupportEmployee);
    const {enableDataDownloads , backgroundColour} = organisationConfig;
    const orderedDashboardsList = metadata?.dashboards || [];
    const [scrollableTabs, setScrollableTabs] = useState(false);
    const [leftStartScrollIndex, setLeftStartScrollIndex] = useState(-1);
    const [rightStartScrollIndex, setRightStartScrollIndex] = useState(-1);
    const filtersObjects = useSelector((state: any) => state.dashboardFilter);
    const entityType = dashboardType === dashboardGroupTypes.CXO? entityTypes.CXO : entityTypes.DASHBOARD_GROUP;
    const {
      onOpen,
      loadingScheduler,
      columns,
      schedules,
      onClose,
      visible,
      data,
      editMetaData,
    } = useSchedulerPage(dashboardGroupId, entityType, onRefetchDashboardData);

    const getCachedDashboards = () => {
      let cachedDashboards: any = [];
      const localCachedDashboard = localStorage.getItem('activeDashboard');
      if (localCachedDashboard) {
        cachedDashboards = JSON.parse(localCachedDashboard);
      }
      return cachedDashboards;
    };

    const handleDashboardTabChange = (tabId, scroll) => {
      const reportIds = dashboards[tabId].dashboardVisualisations.reports.map((reports: any) => reports.reportId);
      dispatch({
        type: CHANGE_DASHBOARD_TAB,
        payload: {
            lastUpdatedTime: 0,
            reportsData: {},
            reportList: reportIds,
        },
      });

      if (tabsRef.current) {
        const selectedTabElement = document.getElementById(`tab${tabId}`);
        if(scroll && selectedTabElement) {
          tabsRef.current.scrollLeft = selectedTabElement.offsetLeft - tabsRef.current.offsetLeft;
        }
      }
      setDashboardTab(tabId);
      suggestedFilter(orderedDashboardsList[tabId]);
      const cachedDashboards = getCachedDashboards();
      const cachedCurrentDashboard = cachedDashboards?.find(
        (activeDashboard) =>
          activeDashboard.dashboardGroupId === dashboardGroupId,
      );
      if (cachedCurrentDashboard) {
        cachedCurrentDashboard.activeTabId =
        orderedDashboardsList[tabId].dashboardVisualisations.dashboard._id;
          //update activeTabId
      } else {
        const newActiveDashboard = {
          dashboardGroupId,
          activeTabId: orderedDashboardsList[tabId].dashboardVisualisations.dashboard._id,
          tabs: {},
          //create new dashboardGroup in Local Storage
        };
        cachedDashboards.push(newActiveDashboard);
      }
      localStorage.setItem('activeDashboard', JSON.stringify(cachedDashboards));
      dispatch({
        type: CHANGE_DASHBOARD_TAB,
        payload: {
            lastUpdatedTime: 0,
            reportsData: {},
        },
      });
    };

    useEffect(() => {
      const interval = setInterval(refetchMetaData, config.TIME_BETWEEN_TWO_FETCH_IN_DASHBOARD_SEC * 1000);
      return () => {
         clearInterval(interval);
         dispatch({
           type: RESET_DASHBOARD_GROUP_NAME_AND_ID,
         });
        dispatch({
          type: INITIALIZE_DASHBOARD_FILTERS,
          payload: [],
        });
      };
    },[]);

    useEffect(() => {
      onRefetchDashboardData();
    }, [dashboardGroupId]);

    useEffect(() => {
      if(tabsRef.current) {
        if (tabsRef.current.scrollWidth > tabsRef.current.clientWidth) {
          setScrollableTabs(true);
        } else {
          setScrollableTabs(false);
          setLeftStartScrollIndex(-1);
          setRightStartScrollIndex(-1);
        }

        const tabsArray = Array.from(tabsRef.current.children);
        for (let i = 0; i < tabsArray.length; i++) {
          const tabElement = tabsArray[i] as HTMLElement;
          const { left, right } = tabElement.getBoundingClientRect();
          if(right < tabsRef.current.scrollLeft) {
            setLeftStartScrollIndex(i);
          }
          if (right > tabsRef.current.clientWidth) {
            setRightStartScrollIndex(i);
            break;
          }
        }
      }
    }, [orderedDashboardsList, inEditMode]);

    useEffect(() => {
      if (isTabletOrMobile) {
        setSelectedLayoutView(LAYOUT_VIEW_TYPES.MOBILE);
      } else {
        setSelectedLayoutView(LAYOUT_VIEW_TYPES.DESKTOP);
      }
    }, [isTabletOrMobile]);

    const [addDashboardtoDBGroup, { loading: additionInProgress, data: movingInfo }] = useLazyQuery(MOVE_DASHBOARD_TO_DASHBOARDGROUP, {
      fetchPolicy: 'network-only',
      onError: data => {
          message.error({key:'creatingDashboard', content: 'Failed Creating Dashboard'});
      },
    });

    const [removeDashboardFromGroup, { loading: removeProgress, data: removeInfo }] = useLazyQuery(REMOVE_DASHBOARD_FROM_DASHBOARDGROUP, {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        setLoading(true);
        setTimeout(() => {
          onRefetchDashboardData();
          setLoading(false);
        }, 2000);
      },
      onError: data => {
          message.error({key:'creatingDashboard', content: 'Failed Removing Dashboard'});
      },
    });

  const [createDashboard, { loading: creationInProgress, data: createdDashboardMetaData }] = useLazyQuery(CREATE_DASHBOARD_FAST_CREATE, {
      fetchPolicy: 'network-only',
      onError: data => {
          message.error({key:'creatingDashboard', content: 'Failed Creating Dashboard'});
      },
      onCompleted: (data) => {
          addDashboardtoDBGroup({ variables: {
              dashboardId: data.createDashboard._id,
              dashboardGroupId,
          }});
          setTimeout(() => {
            onRefetchDashboardData();
            setLoading(false);
          }, 2000);
      },
    });

    const [updateDashoards, { loading: updateProgress, data: updateInfo }] = useLazyQuery(UPDATE_DASHBOARD, {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        onRefetchDashboardData();
      },
      onError: data => {
          message.error({key:'updatingDashboard', content: 'Failed Updating Dashboard'});
      },
    });

    const [getReportsReloadVisualizationData, { loading: loadingExportDashboard }] = useLazyAPI(
      GET_DASHBOARD_REPORTS_RELOADING_DATA,
      {
        type: 'POST',
        onCompleted: (data) => {
          dispatch({ type: SET_REFRESH_REPORTS_DATA, payload: data});
        },
        onError: (data) => {
          message.error({
            key: 'ReportStatus',
            duration: 2,
            content: 'Failed Reloaing Dashboard',
          });
        },
      },
    );
  const dashboardItem = orderedDashboardsList[dashboardTab] || {};
  const { dashboardVisualisations } = dashboardItem;
  const reportIdsAlreadyInDashboard = dashboardVisualisations?.reports
  .map(report => report.reportId);
  const addreports = useAddReportDashboardPage(
    dashboardTab,
    refetchMetaData,
    reportIdsAlreadyInDashboard,
    '',
    canEditDefaultDashboard,
    onRefetchDashboardData,
    isDefaultEditable,
    updateReportPositions,
  );

  const canEdit= (index, name) => {
    setIndexChange(index);
    dispatch({
      type: NAME_CHANGED,
      payload:{
        name,
      },
    });

  };

  const toggleFullscreen = () => {
    if (!isFullScreen) {
      if (document.documentElement.requestFullscreen) {
        setInEditMode(false);
        document.documentElement.requestFullscreen();
        dispatch({ type: SET_FULL_SCREEN, payload: true });
        // undefined is true
        if(!activeCachedDashboard || (activeCachedDashboard.autoRefresh || !activeCachedDashboard.hasOwnProperty('autoRefresh'))){
          dispatch({ type: SET_AUTO_REFRESH, payload: true });
        }
      }
    }
    else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', () => {
      if (!document.fullscreenElement) {
        // this is done to handle esc
        dispatch({ type: SET_FULL_SCREEN, payload: false });
        dispatch({ type: SET_AUTO_REFRESH, payload: false });
        dispatch({ type: SET_REFRESH_REPORTS_DATA, payload: {}});
      }
    });

    return () => {
      document.removeEventListener('fullscreenchange', () => {});
    };
  }, []);

  if(dashboardError) {
    return (
      <StatusDisplay
        image={<NotFoundImage />}
        title={'Dashboard not available. Please contact your admin.'}
      />
    );
  }

  const onAddedNewDashboard = (name: any, id) => {
    if(!name) {
        message.error('Please Provide Dashboard Name to proceed');
    }
    else{
      updateDashoards({variables: {
        id,
        name:updatedName,
    }});
    dispatch({
      type: NAME_CHANGED,
      payload:{
        name:'',
      },
    });
  }
};

  const onCompleteInput = (index,id) => {
    onAddedNewDashboard(updatedName ,id);
    setIndexChange(-1);
  };

  const nameChanged = (name) => {
    dispatch({
      type: NAME_CHANGED,
      payload: {
        name,
      },
    });
  };

  const onVisible = () => {
    dispatch({
        type: CHANGE_DOWNLOAD_MODAL_STATE,
        payload: true,
    });
};

  const removeFromLocalStorage = (dashboardId) => {
    const cachedDashboards = getCachedDashboards();
    const existingActiveDashboard = cachedDashboards?.find(
      (activeDashboard) =>
        activeDashboard.dashboardGroupId === dashboardGroupId,
    );
    if (existingActiveDashboard) {
      const isDashboardIdInExisting = Object.keys(
        existingActiveDashboard.tabs,
      ).includes(dashboardId);
      if (isDashboardIdInExisting) {
        delete existingActiveDashboard.tabs[dashboardId];
      }
    }
    localStorage.setItem('activeDashboard', JSON.stringify(cachedDashboards));
  };
    const { setVisibility, addingReport } = addreports;
    if(fetchInProgress || loading || !orderedDashboardsList || !orderedDashboardsList.length){
      const antIcon = <LoadingOutlined style={{ fontSize: 40, color: '#24538D', margin: '-0.5em 0em 0em -0.5em' }} spin />;
      return <Spin indicator={antIcon} className={classes.loadingDashboardPageSpinner} />;
    }
    const downloadButton = (projectName === projectNames.CUSTOMERLOGISTICS && enableDataDownloads);
    const onSwitchChange = () => {
      dispatch({
        type: DUMMY_DATA_SWITCH,
        payload: {
          enableDummyData: !enableDummyDataSwitch,
        },
      });
    };

    const onAutoRefreshSwitchChange = () => {
      let foundMatch = false;
      const cachedDashboards = getCachedDashboards();
      const updatedArray = cachedDashboards.map(dashboard => {
        if (dashboard.dashboardGroupId === dashboardGroupId) {
            foundMatch = true;
            return { ...dashboard, autoRefresh: !isAutoRefresh };
        }
        return dashboard;
      });
      if (!foundMatch) {
        updatedArray.push({ dashboardGroupId, activeTabId: dashboardVisualisations.dashboard._id, autoRefresh: !isAutoRefresh, tabs: {} });
      }
      localStorage.setItem('activeDashboard', JSON.stringify(updatedArray));
      dispatch({ type: SET_AUTO_REFRESH, payload: !isAutoRefresh });
      dispatch({ type: SET_REFRESH_REPORTS_DATA, payload: {}});
    };

    const onHideDashboardPopOver = () => {
      setPopoverCaretVisible(false);
      setDashboardSearchText('');
    };
    const setPopOverVisibility = (value) => {
      setPopoverCaretVisible(value);
      setDashboardSearchText('');
    };
    const onDeleteDashboard = () => {
      history.push('/dashboard');
    };

    const onRefreshClick = async () => {
      const selectedDashboard = dashboard.dashboards[dashboardTab - parseInt(dashboardTab, 10)];
      const reports = selectedDashboard?.dashboardVisualisations?.reports;
      if (reports && reports.length > 0) {
        const reportIds = reports.map((report) => report.reportId);

        try {
          await getReportsReloadVisualizationData({
            variables: {
              dashboardGroupId,
              reportIds,
              globalFilters: submitableFilter,
              autoReloadFrequency,
            },
          });
          dispatch({ type: SET_LAST_UPDATED_TIME, payload: Date.now() });
        } catch (error) {
          console.error('An error occurred during data refresh:', error);
        }
      }
    };
    const urlSearchParams = new URLSearchParams(location.search);

    const getDummyDataToggle = () => {
      if ((isInternalSupportEmployee || isDefaultEditable || Number(urlSearchParams.get('dummydata'))) && !analyticsTopBar) {
        return (
          <div className={classes.dummyDataToggle}>
            <div style={{whiteSpace: 'nowrap'}}>Dummy Data</div>
            <Switch
              size={'small'}
              checked={enableDummyDataSwitch}
              checkedChildren={'ON'}
              unCheckedChildren={'OFF'}
              onChange={onSwitchChange}
            />
          </div>
        );
      }
      return null;
    };

    const getDownloadsButton = () => {
      if (!downloadButton && projectName === projectNames.CUSTOMERLOGISTICS) {
        return null;
      }
      return (
        <Button
          data-gtm-id="top-nav-downloads"
          className={classes.downloadIcon}
          onClick={onVisible}
          icon={<DownloadIcon />}
        />
      );
    };

    const getExtraRightSideContent = () => {
      if (projectName === projectNames.CUSTOMERLOGISTICS) {
        return (
          <div className={classes.tabBarExtraContent}>
              {getDownloadsButton()}
              {getDummyDataToggle()}
          </div>
        );
      }

      return (
        <div className={classes.tabBarExtraContent}>
          {getDownloadsButton()}
          {isMobile ? <MobileNavBarFilters /> : null}
          {!isTabletOrMobile && (
            <>
              {isFullScreen ? (
                <div className={classes.tabIcons}>
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <Row>Auto Refresh</Row>
                    <Row style={{ fontSize: '7px' }}>
                      Last Refreshed:{' '}
                      {lastUpdatedTime
                        ? getFormattedTime(lastUpdatedTime)
                        : '--: --'}{' '}
                    </Row>
                  </div>
                  <div>
                    <Switch
                      size={'small'}
                      checked={isAutoRefresh}
                      onChange={onAutoRefreshSwitchChange}
                    />
                  </div>
                  {!isAutoRefresh && (
                    <AutoRefresh
                      style={{ cursor: 'pointer' }}
                      onClick={onRefreshClick}
                    />
                  )}
                  <FullScreenExitIcon
                    style={{ cursor: 'pointer' }}
                    onClick={toggleFullscreen}
                  />
                </div>
              ) : (
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                  <FullScreenIcon
                    style={{ cursor: 'pointer' }}
                    onClick={toggleFullscreen}
                  />
                  <Popover
                    content={
                      <DashboardOptions
                        id={dashboardGroupId}
                        name={dashboardGroupName}
                        description={dashboardDescription}
                        category={dashboardCategory}
                        setInEditMode={setInEditMode}
                        downloadButton={downloadButton}
                        isTabletOrMobile={isTabletOrMobile}
                        refetch={onRefetchDashboardData}
                        type={dashboardType}
                        setOpenPopOver={setDashboardOptions}
                        fromDashboardListingPage={false}
                        dashboards={dashboards}
                        onDeleteDashboard={onDeleteDashboard}
                        numSchedules={numSchedules}
                        onOpenScheduler={onOpen}
                        autoReloadFrequency={autoReloadFrequency}
                      />
                    }
                    trigger="click"
                    placement="bottomRight"
                    visible={dashboardOptions}
                    onVisibleChange={setDashboardOptions}
                    style={{ minWidth: '7rem' }}
                  >
                    <Button
                      icon={
                        <EllipsisOutlined
                          style={{
                            fontSize: '20px',
                            color: dashboardOptions ? '#1890FF' : '#000000',
                            opacity: analyticsTopBar ? 0 : 1,
                          }}
                        />
                      }
                    />
                  </Popover>
                </div>
              )}
            </>
          )}
          {(dashboardGroupType === 'user' || isDefaultEditable) &&
          !downloadButton &&
          !isTabletOrMobile ? (
            <div
              className={classes.addReportButton}
              style={{ margin: inEditMode ? '0 0.5rem' : undefined }}
            >
              {inEditMode ? (
                <>
                  <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <Button
                      style={{
                        cursor: 'pointer',
                        borderRadius: '4px',
                        color:
                          selectedLayoutView === LAYOUT_VIEW_TYPES.DESKTOP
                            ? '#fff'
                            : 'black',
                        background:
                          selectedLayoutView === LAYOUT_VIEW_TYPES.DESKTOP
                            ? '#448EF7'
                            : '#fff',
                      }}
                      icon={<DesktopOutlined />}
                      onClick={() =>
                        setSelectedLayoutView(LAYOUT_VIEW_TYPES.DESKTOP)
                      }
                    />
                    <Button
                      style={{
                        cursor: 'pointer',
                        borderRadius: '4px',
                        color:
                          selectedLayoutView === LAYOUT_VIEW_TYPES.MOBILE
                            ? '#fff'
                            : 'black',
                        background:
                          selectedLayoutView === LAYOUT_VIEW_TYPES.MOBILE
                            ? '#448EF7'
                            : '#fff',
                      }}
                      icon={<MobileOutlined />}
                      onClick={() =>
                        setSelectedLayoutView(LAYOUT_VIEW_TYPES.MOBILE)
                      }
                    />
                  </div>
                  <Button
                    data-gtm-id="dashboard-add-report"
                    className={classes.editButtons}
                    onClick={() => setVisibility(true)}
                    type={'primary'}
                  >
                    Add Report
                  </Button>
                </>
              ) : null}
              {!isFullScreen && inEditMode && (
                <Button
                  data-gtm-id="dashboard-edit-mode"
                  className={classes.editButtons}
                  onClick={() => setInEditMode(!inEditMode)}
                  size="small"
                  type={'default'}
                >
                  Remove Edit Mode
                </Button>
              )}
            </div>
          ) : (
            <></>
          )}
          {getDummyDataToggle()}
        </div>
      );
    };

    const getExtraLeftSideContent = () => {
      if(projectName === projectNames.CUSTOMERLOGISTICS) {
        return <Popover
          visible={popoverCaretVisible}
          onVisibleChange={(v) => setPopOverVisibility(v)}
          overlayClassName={classes.dashboardListPopover}
          overlayInnerStyle={{
            maxHeight: '40rem',
            overflow: 'scroll',
            scrollbarWidth: 'none',
          }}
          content={
            <NavigationPane
              searchText={dashboardSearchText}
              setSearchText={setDashboardSearchText}
              hidePopover={onHideDashboardPopOver}
            />
          }
          title="Dashboards"
          trigger="click"
        >
          <div style={{ marginRight: '0.6rem', cursor: 'pointer' }}>
            {dashboardGroupName}
            {popoverCaretVisible ?
              (
                <CaretDownOutlined
                  style={{ marginLeft: '5px' }}
                  onClick={() => setPopoverCaretVisible(!popoverCaretVisible)}
                />
              )
              :
              (
                <CaretRightOutlined
                  style={{ marginLeft: '5px' }}
                  onClick={() => setPopoverCaretVisible(!popoverCaretVisible)}
                />
              )}
          </div>
        </Popover>;
      }
      return <></>;
    };

    const onDragEnd = (result) => {
      if (!result.destination) {
        return;
      }

      const newOrder: any[] = [];
      orderedDashboardsList.forEach((item, index) => {
        if (newOrder.indexOf(index) === -1) {
          newOrder.push(index);
        }
      });

      const dragKey = result.source.index;
      const hoverKey = result.destination.index;
      const dragIndex = newOrder.indexOf(dragKey);
      const hoverIndex = newOrder.indexOf(hoverKey);

      newOrder.splice(dragIndex, 1);
      newOrder.splice(hoverIndex, 0, dragKey);

      const newDashboards = newOrder.map(index => orderedDashboardsList[index]);
      const newDashboardsFilters = newOrder.map(index => filtersObjects[index]);
      const updatedDashboardGroupMetaData = {...metadata};
      updatedDashboardGroupMetaData.dashboards = newDashboards;

      setDashboardTab(hoverIndex.toString());
      dispatch({
        type: SET_DASHBOARD_METADATA,
        payload: updatedDashboardGroupMetaData,
      });
      dispatch({
        type: INITIALIZE_DASHBOARD_FILTERS,
        payload: newDashboardsFilters,
      });
    };

    const handleDeletion = (targetKey) => {
      const targetDashboard = orderedDashboardsList[targetKey as any];
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            Are you sure you want to delete{' '}
            <b>
              {
                targetDashboard.dashboardVisualisations
                  ?.dashboard?.name
              }
            </b>
            ? This action cannot be undone.
          </div>
        ),
        okText: 'Delete',
        onOk() {
          removeFromLocalStorage(
            targetDashboard.dashboardVisualisations.dashboard
              ._id,
          );
          removeDashboardFromGroup({
            variables: {
              dashboardId:
                targetDashboard.dashboardVisualisations
                  .dashboard._id,
              dashboardGroupId,
            },
          });
        },
      });
    };

    const handleAddition = () => {
      const name = `New Dashboard - ${orderedDashboardsList.length}`;
      createDashboard({
        variables: {
          dashboardGroupId,
          name,
        },
      });
    };

    return (
      <>
        <div style={{ width: '100%' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="dashboard-tabs" direction="horizontal">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <div className={classes.draggableTabsParent}
                    style={{
                      justifyContent: !inEditMode ? 'space-between' : 'none',
                      backgroundColor: projectName === projectNames.CUSTOMERLOGISTICS ? backgroundColour : '#F2EDE4',
                    }}>
                    <div ref={tabsRef} className={classes.draggableTabs}>
                        {getExtraLeftSideContent()}
                        {
                          orderedDashboardsList.map((dashboardItem, index) => {
                            const antIcon = (
                              <LoadingOutlined style={{ fontSize: 40, color: '#24538D' }} spin />
                            );
                            return (
                              <Draggable
                                key={index}
                                draggableId={`dashboard-${index}`}
                                index={index}
                                isDragDisabled={!inEditMode}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    id={`tab${index}`}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    onClick={() => handleDashboardTabChange(index.toString(), false)}
                                    className={classes.draggableTab}
                                    style={{
                                      color: dashboardTab === index.toString() ? '#1890ff' : 'rgba(0, 0, 0, 0.85)',
                                      borderBottom: dashboardTab === index.toString() ? '2px solid' : 'none',
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    {additionInProgress || creationInProgress ? (
                                      <Spin indicator={antIcon} />
                                    ) : inEditMode && indexChange === index ? (
                                      <div className={classes.tabInputBox}>
                                        <Input
                                          onKeyDown={(e) => e.stopPropagation()}
                                          style={{ height: '22px', minWidth: '150px' }}
                                          onChange={(e) =>
                                            nameChanged(e.target.value)
                                          }
                                          value={
                                            indexChange !== index
                                              ? dashboardItem.dashboardVisualisations
                                                  .dashboard.name
                                              : updatedName
                                          }
                                        />
                                        <CloseOutlined onClick={() => handleDeletion(index)} />
                                      </div>
                                    ) : (
                                      <div
                                        onDoubleClick={() =>
                                          canEdit(
                                            index,
                                            dashboardItem.dashboardVisualisations
                                              .dashboard.name,
                                          )
                                        }
                                        className={classes.tabRenamingBox}
                                      >
                                        {dashboardItem.dashboardVisualisations
                                          .dashboard.name}
                                        {inEditMode ? <CloseOutlined onClick={() => handleDeletion(index)} /> : null}
                                      </div>
                                    )}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Draggable>
                            );
                          })
                        }
                    </div>
                    <div className={classes.morePlusButton} style={{ flexGrow: inEditMode ? '1' : '0' }}>
                      {scrollableTabs ?
                        <MoreComponent
                          orderedDashboardsList={orderedDashboardsList}
                          startIndex={leftStartScrollIndex}
                          endIndex={rightStartScrollIndex}
                          handleDashboardTabChange={handleDashboardTabChange}
                          handleDeletion={handleDeletion}
                          inEditMode={inEditMode}
                        />: null
                      }
                      {inEditMode ?
                        <div className={classes.addDashboardIcon}>
                          <PlusOutlined onClick={handleAddition} />
                        </div>
                      :null}
                      {getExtraRightSideContent()}
                    </div>
                  </div>
                    <Dashboard
                      dashboardTab={dashboardTab}
                      dashboard={dashboard}
                      inEditMode={inEditMode}
                      setInEditMode={setInEditMode}
                      removeFromLocalStorage={removeFromLocalStorage}
                      getCachedDashboards={getCachedDashboards}
                      dashboardId={dashboardGroupId}
                      isSticky={isSticky}
                      setIsSticky={setIsSticky}
                      isTabletOrMobile={isTabletOrMobile}
                      getReportsReloadVisualizationData={getReportsReloadVisualizationData}
                      selectedLayoutView={selectedLayoutView}
                      onCompleteInput={onCompleteInput}
                    />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <AddReportModal
          {...addreports}
          dashbboardId={dashboardVisualisations?.dashboard._id}
        />
        <ChildReportModal childReportFromDashboard={true} />
        <SchedulerDrawer
          visible={visible}
          onClose={onClose}
          data={data}
          entityType={'dashboardGroup'}
          editMetaData={editMetaData}
        />
      </>
    );
};

export default memo(DashboardPage);
