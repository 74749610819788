import {
  BarChartOutlined,
  PieChartOutlined,
  LineChartOutlined,
  FileExcelOutlined,
  SisternodeOutlined,
  CopyOutlined,
  CheckOutlined,
  ExportOutlined,
} from '@ant-design/icons';

export const mailTypes = {
    TEXT: 'text',
    CXO: 'cxo',
    MJML: 'mjml',
};

export const entityTypes = {
    REPORT: 'report',
    DASHBOARD_GROUP: 'dashboardGroup',
    CXO: 'cxo',
};

export const routeEntityMap = {
    reports: 'report',
    dashboard: 'dashboardGroup',
    cxo: 'cxo',
};

export const entityRouteMap = {
    report: 'reports',
    dashboardGroup: 'dashboard',
    cxo: 'cxo',
};

export const frequencyTypes = {
    YEAR: 'year',
    MONTH: 'month',
    DAY: 'day',
    WEEK: 'week',
    HOUR: 'hour',
    MINUTE: 'minute',
};

export const weekdays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
];

export const weekends = [
    'Saturday',
    'Sunday',
];

export const intervals = {
    DAILY : 'daily',
    WEEKLY_DAY : 'weekly_day',
    MONTHLY_DAY : 'monthly_day',
    YEARLY_DAY: 'yearly_day',
    WEEKLY_WEEKDAYS: 'weekly_weekdays',
    CUSTOM: 'custom',
    HOURLY: 'hourly',
    MINUTELY: 'minutely',
};

export const hourlyIntervals = {
    ONE_HOUR: '1_hours',
    TWO_HOURS: '2_hours',
    FOUR_HOURS: '4_hours',
    SIX_HOURS: '6_hours',
    TWELVE_HOURS: '12_hours',
};

export const schedulerIntervals = [
    { value: intervals.DAILY},
    { value: intervals.WEEKLY_DAY },
    { value: intervals.MONTHLY_DAY },
    { value: intervals.YEARLY_DAY },
    { value: intervals.WEEKLY_WEEKDAYS },
    { value: intervals.CUSTOM },
];

export const iconMap = {
    bar: BarChartOutlined,
    pie: PieChartOutlined,
    line: LineChartOutlined,
};
export const timeFormat = 'HH:mm';
export const dateFormat = 'dddd, MMM Do YYYY';
export const dateTimeFormat = 'dddd, MMM Do YYYY HH:mm:ss';
export const dateHourMinFormat = 'dddd, MMM Do YYYY HH:mm';
export const hoursFormat = 'HH';

export const chartInteractionsList = {
  childReport: {
    key: 'CHILD_REPORT',
    title: 'Child report',
    icon: SisternodeOutlined,
  },
  underlyingDataDownload: {
    key: 'UNDERLYING_DATA_DOWNLOAD',
    title: 'Download underlying data',
    icon: FileExcelOutlined,
  },
  copyToClipBoard: {
    key: 'COPY_VALUE',
    title: 'Copy value',
    icon: CopyOutlined,
  },
  copied:{
    key: 'COPIED',
    title: 'Copied',
    icon: CheckOutlined,
  },
  viewDetails: {
    key: 'VIEW_DETAILS',
    title: 'View Details',
    icon: ExportOutlined,
  },
};

export const dummyDataConst = {
  Min: 'Min',
  Max: 'Max',
  CATEGORICAL: 'CATEGORICAL',
  NUMBERS: 'NUMBERS',
  DATETIME: 'DATE TIME',
  RANGE: 'RANGE',
};

export const filterTimeFormats = {
  hour: 'Do MMM YYYY H:mm',
  day: 'D MMM YYYY',
  week: 'D MMM YYYY',
  month: 'MMM YYYY',
  quarter: 'MMM YYYY',
  year: 'YYYY',
};

export const projectNames = {
  LOGISTICS: 'logistics',
  EXIM: 'exim',
  CUSTOMERLOGISTICS: 'customer-logistics',
  TRANSPORTERPORTAL: 'transporter-portal',
};

export const permissionTypes = {
  USER: 'user',
  ADMIN: 'admin',
  SUPERADMIN: 'super_admin',
};
export const buckets = ['year', 'quarter', 'month', 'week', 'day', 'hour'];

export const importTypes = {
  DASHBOARD: 'Dashboard',
  REPORT: 'Report',
};

export const dashboardFilterTypes = {
  EXHAUSTIVE: 'exhaustive',
  FROZEN: 'freeze',
  SIMPLE: 'simple',
};

export const numberFilter = {
  numberIsGreaterThan: '>',
  numberIsLessThan: '<',
  numberIsEqualTo: '=',
  numberIsNotEqualTo: '!=',
  numberIsNull: 'Null',
  numberIsNotNull: 'Not Null',
};
export const DEFAULT_THEME = 'theme_1';

export const filterType = {
  BOOLEAN: 'boolean',
  NUMBER: 'number',
  TEXT: 'text',
  TIMESTAMPZ: 'timestampz',
  DATE: 'date',
};

export const dbTypes = {
  REDSHIFT: 'redshift',
  POSTGRESQL: 'postgresql',
  MONGO: 'mongo',
  ELASTIC_SEARCH: 'elasticSearch',
};

export const VISUALIZE_TABS = {
  DATA: 'DATA',
  CHART: 'CHART',
  DOWNLOAD: 'DOWNLOAD',
  AUTOMATION: 'AUTOMATION',
};

export const DATAMART_TYPES = {
  MANUAL_QUERY: 'manualQuery',
};

export const HTML_EDITOR_REPORT_TYPE = 'htmlEditor';

export const ALLOW_SANATIZE_TAGS_HTML = ['div', 'iframe', 'img', 'h1'];

export const ALLOW_SANATIZE_ATTRS_HTML = ['src', 'width', 'height', 'style', 'frameborder', 'scrolling'];

export const AUTO_RUN_REPORT_DISABLE = ['htmlEditor'];

export const DEFAULT_HTML_VALUE = `<!--<div style="height: 100%; overflow:hidden">-->
<!--  <iframe-->
<!--      scrolling = "no"-->
<!--      src=""-->
<!--      width="100%" -->
<!--      height="100%" -->
<!--      frameborder="0"-->
<!--      style="display: block;  transform: scale(1);">-->
<!--  </iframe>-->
<!--  <img src="example.jpg" width="280" height="90" style="margin-right: 20px;">-->
<!--</div>-->`;

export const dashboardGroupTypes = {
  USER: 'user',
  CXO: 'cxo',
  DEFAULT: 'default',
};

export const autoReloadFrequencyOptionsMin = [
  { label: '1 m', value: 60000 },
  { label: '2 m', value: 120000 },
  { label: '5 m', value: 300000 },
  { label: '10 m', value: 600000 },
];

export const autoReloadFrequencyOptionsSec = [
  { label: '15 s', value: 15000},
  { label: '30 s', value: 30000},
];

export const DEFAULT_AUTO_RELOAD_FREQUENCY = 300000;

export const NEW_RELIC_CHART_OPTIONS = [
  { value: 'AREA', prettyName: 'Area' },
  { value: 'BAR', prettyName: 'Bar' },
  { value: 'BASELINE', prettyName: 'Baseline' },
  { value: 'BILLBOARD', prettyName: 'Billboard' },
  { value: 'BULLET', prettyName: 'Bullet' },
  { value: 'EMPTY', prettyName: 'Empty' },
  { value: 'EVENT_FEED', prettyName: 'Event Feed' },
  { value: 'FUNNEL', prettyName: 'Funnel' },
  { value: 'HEATMAP', prettyName: 'Heatmap' },
  { value: 'HISTOGRAM', prettyName: 'Histogram' },
  { value: 'JSON', prettyName: 'JSON' },
  { value: 'LINE', prettyName: 'Line' },
  { value: 'MARKDOWN', prettyName: 'Markdown' },
  { value: 'PIE', prettyName: 'Pie' },
  { value: 'SCATTER', prettyName: 'Scatter' },
  { value: 'STACKED_HORIZONTAL_BAR', prettyName: 'Stacked Horizontal Bar' },
  { value: 'TABLE', prettyName: 'Table' },
  { value: 'TRAFFIC_LIGHT', prettyName: 'Traffic Light' },
  { value: 'VERTICAL_BAR', prettyName: 'Vertical Bar' },
];

const defaultDashboardChartSizes = {
  number: { h: 4, w: 3 },
  bar: { h: 8, w: 6 },
  pie: { h: 8, w: 6 },
  pivotTable: { h: 8, w: 6 },
  line: { h: 8, w: 12 },
  table: { h: 10, w: 6 },
  progressBar: { h: 8, w: 6 },
  html: { h: 8, w: 6 },
  map: { h: 10, w: 6 },
};

export const getDashboardChartSizes = (key: number) => {
  switch (key) {
    case 12:
      return defaultDashboardChartSizes;
    case 256:
      return {
        number: { h: 64, w: 64 },
        bar: { h: 100, w: 128 },
        pie: { h: 100, w: 128 },
        pivotTable: { h: 100, w: 128 },
        line: { h: 100, w: 256 },
        table: { h: 100, w: 128 },
        progressBar: { h: 100, w: 128 },
        html: { h: 100, w: 128 },
        map: { h: 100, w: 128 },
      };
    default:
      return defaultDashboardChartSizes;
  }
};

export const LAYOUT_VIEW_TYPES = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
};

export const AutomationValueTypes = [
  {
    value: 'string',
    label: 'String',
  },
  {
    value: 'number',
    label: 'Number',
  },
  {
    value: 'boolean',
    label: 'Boolean',
  },
  {
    value: 'json',
    label: 'JSON',
  },
];

export const commaSeparatorType: { [key: string]: string } = {
  international: 'en-US',
  indian: 'en-IN',
  none: '',
};

export const mapCountryCoordinates = {
  'united arab emirates': [53.847818, 23.424076],
  'andorra': [1.601554, 42.546245],
  'antigua and barbuda': [-61.796428, 17.060816],
  'afghanistan': [67.709953, 33.93911],
  'albania': [20.168331, 41.153332],
  'anguilla': [-63.068615, 18.220554],
  'netherlands antilles': [-69.060087, 12.226079],
  'armenia': [45.038189, 40.069099],
  'antarctica': [-0.071389, -75.250973],
  'angola': [17.873887, -11.202692],
  'american samoa': [-170.132217, -14.270972],
  'argentina': [-63.616672, -38.416097],
  'australia': [133.775136, -25.274398],
  'austria': [14.550072, 47.516231],
  'azerbaijan': [47.576927, 40.143105],
  'aruba': [-69.968338, 12.52111],
  'barbados': [-59.543198, 13.193887],
  'bosnia and herzegovina': [17.679076, 43.915886],
  'belgium': [4.469936, 50.503887],
  'bangladesh': [90.356331, 23.684994],
  'bulgaria': [25.48583, 42.733883],
  'burkina faso': [-1.561593, 12.238333],
  'burundi': [29.918886, -3.373056],
  'bahrain': [50.637772, 25.930414],
  'bermuda': [-64.75737, 32.321384],
  'benin': [2.315834, 9.30769],
  'bolivia': [-63.588653, -16.290154],
  'brunei': [114.727669, 4.535277],
  'bahamas': [-77.39628, 25.03428],
  'brazil': [-51.92528, -14.235004],
  'bouvet island': [3.413194, -54.423199],
  'bhutan': [90.433601, 27.514162],
  'belarus': [27.953389, 53.709807],
  'botswana': [24.684866, -22.328474],
  'canada': [-106.346771, 56.130366],
  'belize': [-88.49765, 17.189877],
  'congo [drc]': [21.758664, -4.038333],
  'cocos [keeling] islands': [96.870956, -12.164165],
  'congo [republic]': [15.827659, -0.228021],
  'central african republic': [20.939444, 6.611111],
  'côte divoire': [-5.54708, 7.539989],
  'switzerland': [8.227512, 46.818188],
  'chile': [-71.542969, -35.675147],
  'cook islands': [-159.777671, -21.236736],
  'china': [104.195397, 35.86166],
  'cameroon': [12.354722, 7.369722],
  'costa rica': [-83.753428, 9.748917],
  'colombia': [-74.297333, 4.570868],
  'cape verde': [-24.013197, 16.002082],
  'cuba': [-77.781167, 21.521757],
  'cyprus': [33.429859, 35.126413],
  'christmas island': [105.690449, -10.447525],
  'germany': [10.451526, 51.165691],
  'czech republic': [15.472962, 49.817492],
  'denmark': [9.501785, 56.26392],
  'djibouti': [42.590275, 11.825138],
  'dominican republic': [-70.162651, 18.735693],
  'dominica': [-61.370976, 15.414999],
  'ecuador': [-78.183406, -1.831239],
  'algeria': [1.659626, 28.033886],
  'egypt': [30.802498, 26.820553],
  'estonia': [25.013607, 58.595272],
  'eritrea': [39.782334, 15.179384],
  'western sahara': [-12.885834, 24.215527],
  'ethiopia': [40.489673, 9.145],
  'spain': [-3.74922, 40.463667],
  'fiji': [179.414413, -16.578193],
  'finland': [25.748151, 61.92411],
  'micronesia': [150.550812, 7.425554],
  'falkland islands [islas malvinas]': [-59.523613, -51.796253],
  'france': [2.213749, 46.227638],
  'faroe islands': [-6.911806, 61.892635],
  'united kingdom': [-3.435973, 55.378051],
  'gabon': [11.609444, -0.803689],
  'georgia': [43.356892, 42.315407],
  'grenada': [-61.604171, 12.262776],
  'guernsey': [-2.585278, 49.465691],
  'french guiana': [-53.125782, 3.933889],
  'gibraltar': [-5.345374, 36.137741],
  'ghana': [-1.023194, 7.946527],
  'gambia': [-15.310139, 13.443182],
  'greenland': [-42.604303, 71.706936],
  'guadeloupe': [-62.067641, 16.995971],
  'guinea': [-9.696645, 9.945587],
  'greece': [21.824312, 39.074208],
  'equatorial guinea': [10.267895, 1.650801],
  'guatemala': [-90.230759, 15.783471],
  'south georgia and the south sandwich islands': [-36.587909, -54.429579],
  'guinea-bissau': [-15.180413, 11.803749],
  'guam': [144.793731, 13.444304],
  'gaza strip': [34.308825, 31.354676],
  'guyana': [-58.93018, 4.860416],
  'heard island and mcdonald islands': [73.504158, -53.08181],
  'hong kong': [114.109497, 22.396428],
  'croatia': [15.2, 45.1],
  'honduras': [-86.241905, 15.199999],
  'hungary': [19.503304, 47.162494],
  'haiti': [-72.285215, 18.971187],
  'ireland': [-8.24389, 53.41291],
  'indonesia': [113.921327, -0.789275],
  'isle of man': [-4.548056, 54.236107],
  'israel': [34.851612, 31.046051],
  'british indian ocean territory': [71.876519, -6.343194],
  'india': [78.96288, 20.593684],
  'iran': [53.688046, 32.427908],
  'iraq': [43.679291, 33.223191],
  'italy': [12.56738, 41.87194],
  'iceland': [-19.020835, 64.963051],
  'jamaica': [-77.297508, 18.109581],
  'jersey': [-2.13125, 49.214439],
  'japan': [138.252924, 36.204824],
  'jordan': [36.238414, 30.585164],
  'kyrgyzstan': [74.766098, 41.20438],
  'kenya': [37.906193, -0.023559],
  'kiribati': [-157.360000, -3.070000],
  'north korea': [127.510093, 40.339852],
  'south korea': [127.766922, 35.907757],
  'kuwait': [47.481766, 29.31166],
  'kazakhstan': [66.923684, 48.019573],
  'laos': [102.495496, 19.85627],
  'lebanon': [35.862285, 33.854721],
  'liberia': [-9.429499, 6.428055],
  'libya': [17.228331, 26.3351],
  'morocco': [-7.09262, 31.791062],
  'monaco': [7.424616, 43.738416],
  'moldova': [28.39417, 47.411631],
  'montenegro': [19.37332, 42.708678],
  'madagascar': [46.869107, -18.766947],
  'marshall islands': [168.000049, 7.109535],
  'mexico': [-102.552784, 23.634501],
  'malawi': [34.301525, -13.254308],
  'malaysia': [101.975766, 4.210484],
  'mozambique': [35.529562, -18.665695],
  'namibia': [18.49041, -22.95764],
  'new zealand': [174.885971, -40.900557],
  'nicaragua': [-85.207229, 12.865416],
  'niger': [8.081666, 17.607789],
  'nigeria': [8.675277, 9.082],
  'niue': [-169.867233, -19.054445],
  'norfolk island': [168.169151, -29.040835],
  'northern mariana islands': [145.38469, 15.0979],
  'norway': [8.468946, 60.472024],
  'nepal': [84.124008, 28.394857],
  'the netherlands': [5.291266, 52.132633],
  'new caledonia': [165.618042, -22.28552],
  'northern cyprus': [33.294855, 35.25485],
  'palau': [134.58252, 7.514850],
  'panama': [-80.782127, 8.537981],
  'papua new guinea': [143.95555, -6.315252],
  'paraguay': [-58.443832, -23.442036],
  'peru': [-75.015152, -9.189967],
  'philippines': [121.774017, 12.879721],
  'poland': [19.145135, 51.919438],
  'portugal': [-8.224454, 39.399872],
  'puerto rico': [-66.590149, 18.220833],
  'qatar': [51.20381, 25.354826],
  'romania': [24.96676, 45.943161],
  'russia': [105.318756, 61.52401],
  'rwanda': [29.873888, -1.940278],
  'saint barthelemy': [-62.848118, 17.898576],
  'saint helena': [-5.700, -15.965],
  'saint kitts and nevis': [-62.782998, 17.357822],
  'saint lucia': [-60.978893, 13.909444],
  'saint martin': [-63.893737, 18.070000],
  'saint pierre and miquelon': [-56.194393, 46.769196],
  'saint vincent and the grenadines': [-61.287228, 13.252814],
  'samoa': [-172.104629, -13.759029],
  'san marino': [12.446303, 43.93333],
  'sao tome and principe': [6.613081, 0.18636],
  'saudi arabia': [45.079162, 23.885942],
  'senegal': [-14.452362, 14.497401],
  'serbia': [21.005859, 44.016521],
  'seychelles': [55.491977, -4.679574],
  'sierra leone': [-11.779889, 8.460555],
  'singapore': [103.819836, 1.352083],
  'slovakia': [19.699024, 48.669026],
  'slovenia': [14.995463, 46.151241],
  'solomon islands': [160.000124, -9.64571],
  'somalia': [46.199616, 5.152149],
  'south africa': [22.937506, -30.559482],
  'south sudan': [31.30196, 6.876992],
  'sudan': [30.217636, 12.862807],
  'suriname': [-56.027784, 3.919305],
  'svalbard and jan mayen': [18.971817, 77.000000],
  'sweden': [18.643501, 60.128161],
  'syria': [38.996815, 34.802075],
  'taiwan': [120.960515, 23.697814],
  'tajikistan': [71.276093, 38.861034],
  'tanzania': [34.888822, -6.369028],
  'thailand': [100.992541, 15.870032],
  'togo': [0.824782, 8.619543],
  'tonga': [-175.198242, -21.178986],
  'trinidad and tobago': [-61.222503, 10.691803],
  'tunisia': [9.537499, 33.886917],
  'turkmenistan': [59.556278, 40.062425],
  'turkey': [35.243322, 38.963745],
  'tuvalu': [179.194026, -7.109535],
  'vanuatu': [166.959158, -15.376706],
  'vatican city': [12.453389, 41.902782],
  'venezuela': [-66.58973, 6.42375],
  'united states': [-95.712891, 37.09024],
  'uruguay': [-55.765835, -32.522779],
  'uzbekistan': [64.585162, 41.377491],
  'vietnam': [108.277199, 14.058324],
  'wallis and futuna': [-178.250896, -13.30145],
  'yemen': [48.516388, 15.552979],
  'zambia': [27.849332, -13.133897],
  'zimbabwe': [29.154857, -19.015438],
  default: [77.20837908317822, 28.609109150583123],
};